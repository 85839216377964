import styled from 'styled-components'
import presets from '../utils/presets'
import {rhythm} from '../utils/typography'

const Form = styled.form`
  max-width: ${rhythm(16)};
  margin: 0 auto;

  p{
    text-align:left;
    text-transform: uppercase;
    color: ${presets.faded};
  }

  label{
    font-size: 0.8rem;
    .required{color:${presets.error};}
  }

  input, textarea{
    background-color: transparent;
    font-size: 1rem;
    width:100%;
    margin-top:${rhythm(0.25)};
    padding: ${rhythm(0.2)} 0;
    border: 1px solid ${presets.faded};
    border-radius: ${rhythm(0.1)};
    border-radius: 3px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid ${presets.faded};
    ::placeholder{
      text-transform: lowercase;
      color: #aaa;
    }

    ${'' /* get rid of firefox red required box  */}
    :required{
      ${'' /* box-shadow: none; */}
    }

    ${'' /* get rid of chrome blue border around focused boxes */}
    :focus{
      ${'' /* outline: none; */}
    }

    :focus{
      :valid{border-bottom: 1px solid ${presets.brand}}
      :invalid{border-bottom: 1px solid ${presets.error}}
    }

    ${'' /* :valid{background-color: ${presets.brand}} */}
    ${'' /* :invalid{border-bottom: 1px solid ${presets.error}} */}

    ${'' /* style invalid selectors only when they aren't currently being edited */}
    :not(:focus):invalid {
      ${'' /* background-color: ${presets.error}; */}
    }

  }

  .hidden{display:none;}
`;

export default Form
