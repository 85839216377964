import styled from 'styled-components'
import presets from '../utils/presets'
import {rhythm} from '../utils/typography'

const Button = styled.button`
  display:inline-block;
  margin: 0 auto;
  margin-bottom: ${rhythm(1)};
  border: 2px solid ${props => props.brand ? presets.brand : presets.dark };
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: ${rhythm(0.1)};
  font-weight: 900;
  background-color: ${props => props.brand ? presets.brand : 'transparent' };
  padding: ${rhythm(0.5)} ${rhythm(1)};
  :hover{
    cursor: pointer;
  }
`;

export default Button
