import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const H1 = styled.h1`
  margin:0 auto;
  margin-bottom: ${rhythm(1)};
  /* text-align: ${props => props.center ? 'center' : 'left'}; */
  text-transform: capitalize;
`;

export default H1
