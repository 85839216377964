import React from 'react'
// import presets from '../utils/presets'
// import {rhythm} from '../utils/typography'
// import Link from 'gatsby'
// import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'

import Container from '../com/Container'
import Footer from '../com/Footer'
import Diagonal from '../com/Diagonal'
// import Nav from '../com/Nav'
import Section from '../com/Section'
import H1 from '../com/H1'
import H2 from '../com/H2'

import P from '../com/P'


import Form from '../com/Form'
import Button from '../com/Button'

import LayoutMain from '../com/LayoutMain'

const formname = 'contact'

function encode(data) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formname, ...this.state })
    })
      .then(() => navigate("/thank-you/"))
      .catch(error => alert(error));

    e.preventDefault();
  };

    render() {
    // const { from } = this.props.location.state || '/'
    // const { fireRedirect } = this.state
    return (
      <LayoutMain>
        <Helmet>
          <title>Start An Estimate | Contact</title>
          <meta name="description" content="" />
        </Helmet>
        <header>
          <Diagonal>
            <Container>
                <H1>start an estimate</H1>
            </Container>
          </Diagonal>
        </header>
        <Container center>
          <Section>
            <H2>Let's talk.</H2>
            <P>We reply as quickly as we can <span role="img" aria-label="Smiley Face">&#128522;</span> but sometimes it can be a day or two.</P>
            <Form
          name={formname}
          data-netlify-honeypot="bot-field"
          method="POST"
          action="/thank-you/"
          data-netlify="true"
          onSubmit={this.handleSubmit}
          >
            <P className="hidden">
              <label>Don’t fill this out if you're human: <input type="text" name="bot-field" /></label>
            </P>
            <P>
              <label>your full name
                <span className="required"> *required</span>
              </label>
              <input required aria-required="true" type="text" name="name"  placeholder="What should we call you?" onChange={this.handleChange} pattern="[A-Za-z-0-9]+\s[A-Za-z-'0-9]+"/>
            </P>
            <P>
              <label>your address and postcode</label>
              <textarea type="text" name="address" defaultValue={""} placeholder="Where would you like us?" onChange={this.handleChange} />
            </P>

            <P>
              <label>your e-mail</label>
              <input type="email" name="email"  placeholder="So we can send our estimate" onChange={this.handleChange}
                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
              />
            </P>
            <P>
              <label>your phone number
                <span className="required"> *required</span>
              </label>
              <input required aria-required="true" type="tel" name="phone"  placeholder="01234 567890" onChange={this.handleChange} pattern="[0-9]{5}\s?[0-9]{6}"/>
            </P>
            <Button type="submit" brand>start an estimate</Button>
          </Form>
          </Section>
          <Section>
            <H2><span role="img" aria-label="Smartphone">&#128241;</span> Phone</H2>
            <P><a href="tel:07740123895">07740 123 895</a></P>
            <P>We are often busy decorating and don't always catch the phone, but you can contact Lydia directly.</P>
            <H2><span role="img" aria-label="Email">&#128231;</span> E-mail</H2>
            <P><a href="mailto:lydia@dec-hands.co.uk">lydia@dec-hands.co.uk</a></P>
          </Section>
          <Footer />
        </Container>
      </LayoutMain>
    );
  }
}
